<template>
  <div>
    <vca-column class="event-card" :class="'event-' + event.type_of_event">
      <vca-row>
        <h2>{{ $t("events.informations.header") }}</h2>
        <EventApplicationManage
          v-if="canEdit || isEventAsp(event)"
          :showApplication="showApplication"
          :event="event"
        />
      </vca-row>
      <EventApplicationDetails :event="event" />
      <div v-if="showApplication && event.isPublished">
        <br />
        <CrewEventApplicationParticipation
          v-if="event.type_of_event == 'crew_meeting'"
          @close="close()"
          :event="event"
        />
        <EventApplicationParticipation v-else @close="close()" :event="event" />
      </div>
    </vca-column>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EventApplicationManage from "@/components/events/application/EventApplicationManage";
import EventApplicationDetails from "@/components/events/application/EventApplicationDetails";
import EventApplicationParticipation from "@/components/events/application/EventApplicationParticipation";
import CrewEventApplicationParticipation from "@/components/events/application/CrewEventApplicationParticipation";
export default {
  name: "EventApplication",
  components: {
    EventApplicationManage,
    EventApplicationDetails,
    EventApplicationParticipation,
    CrewEventApplicationParticipation,
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
    showApplication: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    isEventAsp(value) {
      return (
        this.user && value.event_asp_id && this.user.id == value.event_asp_id
      );
    },
  },
  created() {
    this.create.event_id = this.event.id;
    this.create.user_id = this.user ? this.user.id : "";
  },
  computed: {
    ...mapGetters({
      user: "user/current",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    current: {
      get() {
        return this.$store.state.user.participations.current;
      },
      set(value) {
        this.$store.commit("user/participations/current", value);
      },
    },
    canEdit() {
      return (
        this.hasSystemPermission() ||
        (this.hasPoolPermission(this.poolEventPermissions) &&
          this.user.crew &&
          this.event.crew &&
          this.user.crew.crew_id == this.event.crew.id)
      );
    },
    create: {
      set(value) {
        this.$store.commit("events/participations/create", value);
      },
      get() {
        return this.$store.state.events.participations.create;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.event-card {
  h2 {
    font-size: 1.5em;
    margin-top: 10px;
  }

  padding: 10px;
  border-radius: 5px;
}
</style>
